<template>
  <v-card class="ma-4">
    <v-card-title>
      Schedule Ride
    </v-card-title>
    <v-card-text class="mb-5">This page is for student drivers to schedule a church ride which other students can register for.<br>
                              Note: RideShare will use your email to notify you of updates to your ride.</v-card-text>
    <v-form ref="rideShareFormRef">
      <v-row>
        <v-col md="4">
          <v-menu ref="menu" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="rideShareFormData.departureDate" label="Departure Date" v-on="on" outlined class="mx-4"></v-text-field>
            </template>
            <v-date-picker
              v-model="rideShareFormData.departureDate"
              label="Departure date"
              class="mx-4"
              outlined
              color="grey"
              :min="date"
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="4">
          <v-menu ref="timeMenu" transition="scale-transition" offset-y min-width="290px" :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="rideShareFormData.departureTime" label="Departure Time" v-on="on" outlined class="mx-4"></v-text-field>
            </template>
            <v-time-picker
              v-model="rideShareFormData.departureTime"
              label="Departure time"
              class="mx-4"
              outlined
              color="grey"
            >
            </v-time-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6" align="center">
          <v-autocomplete
            label="Search for a Church by Name"
            :loading="churchesLoading"
            v-model="rideShareFormData.selectedChurch"
            :items="rideShareChurchList"
            outlined
            hide-no-data
            class="mx-4 justify-content"
            clearable
            :rules="[v => !!v || 'Required']"
            :search-input.sync="churchSearch"
            disable-lookup
          >
            <template v-slot:selection="{ item }">
              {{ item.value.name }} <v-chip>{{ item.value.denomination }}</v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="auto">
          <v-dialog v-model="showAddChurchDialog" max-width="650px" :scrollable="false">
            <template v-slot:activator="{ on }">
              <v-btn size="medium" :disabled="!!rideShareFormData.selectedChurch" v-on="on" class="mt-2">Add New Church</v-btn>
            </template>
            <v-card>
              <v-card-title>
                Add New Church Data
              </v-card-title>
              <v-card-text>
                This church information will be added to the RideShare system
              </v-card-text>
              <v-form
                ref="addChurchFormRef"
                class="mx-4"
              >
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      label="Church Name"
                      v-model="churchToAddName"
                      :rules="[v => !!v || 'Required']"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-select
                      label="Church Denomination"
                      v-model="churchToAddDenomination"
                      :rules="[v => !!v || 'Required']"
                      :items="denominationOptions"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <v-btn class="my-4 mx-2" @click="addNewChurch">Submit</v-btn>
                <v-btn class="my-4 mx-2" @click="showAddChurchDialog = false">Close</v-btn>
              </v-form>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-select
            md="3"
            class="mx-4"
            v-model="rideShareFormData.pickupLocation"
            label="Pickup location"
            required
            :rules="[v => !!v || 'Required']"
            :items="[{ text: 'Carter Circle', value: 'Carter Circle' }, { text: 'Mac Circle', value: 'Mac Circle' }]"
            outlined
          >
          </v-select>
        </v-col>
        <v-col md="6">
          <v-text-field
            class="mx-4"
            type="number"
            v-model="rideShareFormData.seatsAvailable"
            label="Total seats available"
            :rules="[v => !!v || 'Required', v => v >= 1 || 'Minimum is 1', v => v < 15 || 'Maximum is 15']"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            class="mx-4"
            v-model="rideShareFormData.carDescription"
            label="Car description"
            :rules="[v => !!v || 'Required']"
            :props="{ hint: 'Enter a recognizable description of your car (e.g. brand, body, color)' } "
            outlined
            hint="Please provide a brief description of your car so that riders will know what car to look for on the day of the ride."
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <v-checkbox
            v-model="rideShareFormData.recurringWeekly"
            label="Recurring weekly"
            class="mx-4"
            color="grey">
            <template v-slot:append>
              <v-tooltip bottom max-width="250px">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary">fas fa-question-circle</v-icon>
                </template>
                <span>Recurring rides will reschedule themselves every week until the specified end date</span>
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-col>
        <v-col md="4">
          <v-menu ref="menu2" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="rideShareFormData.endDate" label="End Date" v-on="on" outlined class="mx-4"></v-text-field>
            </template>
            <v-date-picker
              :disabled="!rideShareFormData.recurringWeekly"
              v-model="rideShareFormData.endDate"
              label="Departure date"
              class="mx-4"
              outlined
              color="grey"
              :min="date"
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="scheduleRide" class="mx-4">Submit</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<style>
.v-stepper__step__step .v-icon.v-icon {
  font-size: 1rem
}
</style>
<script>
import { watch, onMounted, ref } from '@vue/composition-api'
// import PortalForm from '@/components/forms/Form'
import router from '../../router'
export default {
  // components: {
  //   PortalForm
  // },
  setup (props, { root }) {
    const churchesLoading = ref(false)
    const rideShareChurchList = ref([])
    const date = ref(new Date().toISOString().substring(0, 10))
    const showAddChurchDialog = ref(false)
    const addChurchFormRef = ref(null)
    const churchToAddName = ref('')
    const churchToAddDenomination = ref('')
    const rideShareFormRef = ref(true)
    const churchSearch = ref('')

    const denominationOptions = [
      { text: 'Adventist', value: 'Adventist' },
      { text: 'Anglican', value: 'Anglican' },
      { text: 'Baptist', value: 'Baptist' },
      { text: 'Catholic', value: 'Catholic' },
      { text: 'Episcopal', value: 'Episcopal' },
      { text: 'Lutheran', value: 'Lutheran' },
      { text: 'Methodist', value: 'Methodist' },
      { text: 'Nondenominational', value: 'Nondenominational' },
      { text: 'Presbyterian', value: 'Presbyterian' },
      { text: 'Pentecostal', value: 'Pentecostal' },
      { text: 'Restorationist', value: 'Restorationist' },
      { text: 'Other', value: 'Other' }
    ]

    const rideShareFormData = ref({
      departureDate: date.value,
      departureTime: '09:00',
      pickupLocation: '',
      seatsAvailable: null,
      carDescription: '',
      recurringWeekly: false,
      selectedChurch: null,
      endDate: date.value
    })

    onMounted(() => {
      loadChurches()
    })

    const service = root.$feathers.service('/ride-share/available-rides')

    function scheduleRide (event) {
      event.preventDefault()
      if (!rideShareFormRef.value.validate()) {
        return
      }

      // Copy form data into new object
      const c = rideShareFormData.value
      var data = {
        departureDate: c.departureDate,
        departureTime: c.departureTime,
        pickupLocation: c.pickupLocation,
        seatsAvailable: c.seatsAvailable,
        carDescription: c.carDescription,
        recurringWeekly: c.recurringWeekly,
        selectedChurch: c.selectedChurch,
        endDate: c.endDate
      }

      // if (typeof (data.churchDenomination) === 'object') {
      //   data.churchDenomination = data.churchDenomination.value
      // }
      // if (typeof (data.churchName) === 'object') {
      //   data.churchName = data.churchName.value
      // }

      data.churchName = data.selectedChurch.name
      data.churchDenomination = data.selectedChurch.denomination

      // Take care of floating point numbers
      data.seatsAvailable = Math.floor(data.seatsAvailable)

      // Format date
      let d = data.departureDate

      // Check if the date has been up dated
      if (!(d instanceof Date)) {
        let departureDate2 = new Date(d.substring(0, 4), parseInt(d.substring(5, 7)) - 1, d.substring(8, 10))
        let time = data.departureTime.split(':')
        departureDate2.setHours(time[0])
        departureDate2.setMinutes(time[1])
        departureDate2.setSeconds(0)
        departureDate2.setMilliseconds(0)
        data.departureDate = departureDate2
      }
      const today = new Date()

      data.dateRange = {
        start: today,
        end: data.departureDate
      }

      d = data.endDate
      // // Set the end Date to an actual date object
      if (!(d instanceof Date)) {
        let endDate = new Date(d.substring(0, 4), parseInt(d.substring(5, 7)) - 1, d.substring(8, 10))
        let time = data.departureTime.split(':')
        endDate.setHours(time[0])
        endDate.setMinutes(time[1])
        endDate.setSeconds(0)
        endDate.setMilliseconds(0)
        data.endDate = endDate
      }

      // Handle date ranges
      if (data.recurringWeekly) {
        data.dateRange.end = data.endDate
      }

      // Cast falsey values
      data.recurringWeekly = !!data.recurringWeekly

      // Post service
      service.create(data).then(() => {
        let snackbar = { active: true, color: 'success', timeout: 6000, text: 'Successfully scheduled ride!' }
        root.$store.dispatch('main/snackbar', snackbar)

        router.push({ path: '/ride-share' })
      }).catch((err) => {
        let snackbar = { active: true, color: 'error', timeout: 6000, text: err }
        root.$store.dispatch('main/snackbar', snackbar)
      })
    }

    const churchesService = root.$feathers.service('/ride-share/churches')

    function loadChurches () {
      // If already loading, return
      if (churchesLoading.value) {
        return
      }

      let query = {
        $sort: {
          name: 1
        }
      }

      if (rideShareFormData.value.selectedChurch) {
        // console.log('Name: ' + rideShareFormData.value.selectedChurch.name)
        query.name = rideShareFormData.value.selectedChurch.name
      }

      churchesLoading.value = true

      churchesService.find({ query })
        .then((res) => {
          let list = []

          for (let item of res.data) {
            list.push({
              text: item.name,
              value: item
            })
          }
          rideShareChurchList.value = list
        })
        .catch((err) => {
          let snackbar = { active: true, color: 'error', timeout: 6000, text: err }
          root.$store.dispatch('main/snackbar', snackbar)
        })
        .finally(() => {
          churchesLoading.value = false
        })
    }

    function addNewChurch () {
      if (addChurchFormRef.value.validate()) {
        if (!confirm('Are you sure you want to add this church to the list of churches? It will show up when scheduling a ride. Please only add real church data. All RideShare users will be able to see this.')) {
          addChurchFormRef.value.reset()
          showAddChurchDialog.value = false
          loadChurches()
          return
        }
        // Input is valid
        churchesService.create({
          name: churchToAddName.value,
          denomination: churchToAddDenomination.value
        })
          .then((res) => {
            addChurchFormRef.value.reset()
            showAddChurchDialog.value = false
            loadChurches()
            let snackbar = { active: true, color: 'success', timeout: 6000, text: 'Successfully added church to system!' }
            root.$store.dispatch('main/snackbar', snackbar)
          })
          .catch((err) => {
            let snackbar = { active: true, color: 'error', timeout: 6000, text: err }
            root.$store.dispatch('main/snackbar', snackbar)
          })
      }
    }

    watch(churchSearch, (val) => {
      // Real time search for churches
      if (churchesLoading.value) {
        return
      }

      let query = {
        $sort: {
          name: 1
        }
      }

      if (val) {
        console.log('Name: ' + val)
        query.name = { $regex: val, $options: 'i' }
      }

      churchesLoading.value = true

      churchesService.find({ query })
        .then((res) => {
          let list = []

          for (let item of res.data) {
            list.push({
              text: item.name,
              value: item
            })
          }
          rideShareChurchList.value = list
        })
        .catch((err) => {
          let snackbar = { active: true, color: 'error', timeout: 6000, text: err }
          root.$store.dispatch('main/snackbar', snackbar)
        })
        .finally(() => {
          churchesLoading.value = false
        })
    })

    return {
      rideShareFormData,
      scheduleRide,
      service,
      addNewChurch,
      loadChurches,
      rideShareChurchList,
      churchesLoading,
      showAddChurchDialog,
      addChurchFormRef,
      churchToAddName,
      churchToAddDenomination,
      rideShareFormRef,
      date,
      churchSearch,
      denominationOptions
    }
  }

}
</script>
